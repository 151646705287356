
import { loadStripe } from "@stripe/stripe-js"

export default {
  layout: "clean",
  data: () => ({
    year: null,
    loading: false,
    paymentCompleted: false,
    selectedCurr: [],
    model: {
      email: "",
      refNumber: "",
      amount: "",
      currency: [
        { text: "EUR - Euro", value: "EUR" },
        { text: "GBP - British Pound", value: "GBP" },
        { text: "USD - US Dollar", value: "USD" },
        { text: "SGD - Singapore Dollar", value: "SGD" },
        { text: "AUD - Australian Dollar", value: "AUD" },
      ],
      name: "",
      cardNumber: "",
      cvc: "",
      month: null,
      year: null,
      paymentMethodId: "",
    },
  }),
  head() {
    return {
      title: "Evercam | Payment",
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  created() {
    this.model.year = parseInt(this.$moment().format("YY"))
    this.model.month = this.$moment().month() + 1
    this.selectedCurr = { text: "EUR - Euro", value: "EUR" }
  },
  methods: {
    async makePayment() {
      this.$axios.setToken(this.$config.public.stripePublishKey, "Bearer")
      this.$axios.setHeader("Content-Type", "application/x-www-form-urlencoded")
      try {
        const { data } = await this.$axios.post(
          `${this.$config.public.stripeApiUrl}/payment_methods?type=card&card[number]=${this.model.cardNumber}&card[exp_month]=${this.model.month}&card[exp_year]=${this.model.year}&card[cvc]=${this.model.cvc}`
        )
        this.paymentMethodId = data.id
        this.chargePayment()
      } catch (error) {
        this.$notifications.error({
          error: error.response?.data?.error || error,
        })
      }
    },
    async chargePayment() {
      this.$axios.setHeader("Content-Type", "application/json")
      try {
        const { data } = await this.$axios.post("/payments", {
          amount: parseInt(this.model.amount),
          currency: this.selectedCurr.value,
        })
        this.confirmPayment(data)
      } catch (error) {
        this.$notifications.error({
          error: error.response?.data?.error || error,
        })
      }
    },
    async confirmPayment(data) {
      let stripe = await loadStripe(this.$config.public.stripePublishKey)
      stripe
        .confirmCardPayment(data.clientSecret, {
          payment_method: this.paymentMethodId,
          receipt_email: this.model.email,
        })
        .then(() => {
          this.paymentCompleted = true
        })
    },
    getRange(start, length) {
      return Array.from({ length }, (_, index) => index + start)
    },
  },
}
